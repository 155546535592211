import React from 'react'
import "../../assets/pages/tacct/tacct-home.scss";
import CommonCard from '../../components/CommonCard';
import HeaderNav from '../../components/HeaderNav';

function tacct() {
  const cards= [
    {
      iconName: 'Files',
      linkTo: '/tacct/blue-list',
      labelText: 'Blue List Content Category & Subcategory',
      labelInfo: 'Blue List Content Category & Subcategory values describe the main message being delivered through in the given content',
    },
    {
      iconName: 'Files',
      linkTo: '/tacct/business-unit',
      labelText: 'Business Unit',
      labelInfo: 'Business Unit names and abbreviations provide standardized spelling, formatting, and single source of truth for Lilly operational and analytical platforms & systems',
    },
    {
      iconName: 'Files',
      linkTo: '/tacct/grammatical-form',
      labelText: 'Grammatical Form',
      labelInfo: 'Grammatical Form values provide standardized values to identify text-based content that are questions, declarations, or commands',
    },
    {
      iconName: 'Files',
      linkTo: 'tacct/tonality-ops',
      labelText: 'Tonality',
      labelInfo: 'Tonality values provide standardized values to identify text-based content that based on the semantic tone of how the message is being conveyed',
    },
    // ... add more cards here
  ]

  return (
    <div>
    <HeaderNav msg={"Taxonomy Attributes & Content Classification Tool"}/>
    <div className='container'>
    <div className='tacct-tiles col'>
    {cards.map((card,index) =>(
      <CommonCard key={index}{...card} />
    ))}</div>
    </div>
    </div>
  )
}

export default tacct;
  